.headerBgColor {
  background: #f2f2f2b7 0% 0% no-repeat padding-box;
}

.headerUntouchedColor {
  /* font: normal normal bold 42px/50px Lato; */
  letter-spacing: 0px;
  color: #5ECB3C;
}

.headerInvestColor {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
}

.investNowColor {
  font: normal normal bold 32px/39px;
  letter-spacing: 0px;
  color: #131313;
}

.headerWhyinvestColor {
  letter-spacing: 0px;
  color: #131313;
  opacity: 1;
}

.contactInfo {
  background: #f2f2f2;
  opacity: 0.5;
}

.contactInfoColor {
  letter-spacing: 0px;
  color: #1f91d2;
  opacity: 1;
}

.contactInfoHeaders {
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.reactSlider {
  width: 135px;
  height: 36px;
  background: rgb(250, 244, 244) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 33px;
  opacity: 1;
}

.label {
  top: 0%;
  transform: translateY(-50%);
  font-size: 11px;
  color: rgba(37, 99, 235, 1);
}
.empty input:not(:focus) + .label {
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}
input:not(:focus) + .label {
  color: rgba(70, 70, 70, 1);
}
input {
  border-width: 1px;
}
input:focus {
  outline: none;
  border-color: rgba(37, 99, 235, 1);
}

.rangeslider-horizontal .rangeslider__handle {
  width: 0px !important;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider .rangeslider__handle {
  background: #5ECB3C;
  border: none !important;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  /* box-shadow: 0 1px 3px rgb(22 216 46 / 40%), 0 -1px 3px rgb(64 245 145 / 40%); */
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #5ECB3C !important;
  border-radius: 10px;
  top: 0;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: none !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  /* width: 16px;
    height: 16px;
    top: 6px;
    left: 6px; */
  width: 18px;
  height: 18px;
  top: 6px;
  left: -5px !important;
  border-radius: 50%;
  background-color: #5ECB3C !important;
  box-shadow: none !important;
}

.rangeslider-horizontal {
  height: 9px !important;
  border-radius: 10px;
  cursor: pointer;
}

.redirectPage {
  /* font: normal normal 300 14px/16px Roboto;*/
  font-size: smaller;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.aboutUntouched {
  /* font: normal normal normal 18px/24px Roboto; */
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.footerh1 {
  /* font: normal normal normal 16px/16px Lato; */
  letter-spacing: 0px;
  color: #131313;
  opacity: 1;
}

.footerh2 {
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #131313;
  opacity: 1;
}


.btnUrl:focus{
  background: #5ECB3C 0% 0% no-repeat padding-box;
  width: 58px;
  height: 25px;
}

.btnFocus {
  background: #5ECB3C 0% 0% no-repeat padding-box !important;
}

.btnUrl{
  /* background: #08D01F 0% 0% no-repeat padding-box; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 58px;
  height: 25px;
}

.btnFormBg {
  background: #E1E5EA 0% 0% no-repeat padding-box;
}

.investAmountColor {
  font: normal normal bold 24px/29px Lato;
  letter-spacing: 0px;
  color: #131313;  
}

.backgroundColorRight {
  background: #5ECB3C 0% 0% no-repeat padding-box;
}

.fullWidth {
  width: fit-content;
}